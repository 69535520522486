const config = {
  apiGateway: {
    REGION: 'us-east-2',
    INTERNAL_API_URL: 'https://vy7pzyird2.execute-api.us-east-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://g2c8535lcl.execute-api.us-east-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.11.2',
    HOSTNAME: 'https://teams-service.staging.vale.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.vale.forwoodsafety.com',
    WEBSOCKET: 'wss://jiap0cb7vj.execute-api.us-east-2.amazonaws.com/staging'
  },
};

export default config;
